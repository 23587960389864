var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"is-inline-flex is-relative"},[_c('button',{staticClass:"upAndDownButton",attrs:{"disabled":_vm.loading,"data-test-id":_vm.DATA_TEST_ID_VALUES.QUOTE_ACTIONS_BUTTON},on:{"click":_vm.onShowMoreButtonClick}},[_c('IconMore',{staticClass:"icon color-13"})],1),_c('b-message',{ref:"popup",staticClass:"PopoverActions",attrs:{"title":"Choose an Action","aria-close-label":"Close message"},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},[(!_vm.hideElementByCondition)?_c('div',{on:{"click":_vm.handleDuplicateClick}},[_c('button',{attrs:{"disabled":_vm.loading || _vm.historyView.isActive,"data-test-id":_vm.DATA_TEST_ID_VALUES.DUPLICATE_QUOTE_BUTTON}},[_c('IconDuplicate',{staticClass:"icon color-8 is-xsmall mr-1"}),_vm._v(" Duplicate ")],1)]):_vm._e(),(!_vm.historyView.isActive)?_c('div',{on:{"click":function () {
          _vm.onShowMoreButtonClick();
          _vm.displayIsViewContractSalesManualModal();
        }}},[_c('button',{attrs:{"disabled":_vm.loading}},[_c('IconEye',{staticClass:"icon color-8 is-xsmall mr-1"}),_vm._v(" View Sales Manuals ")],1)]):_vm._e(),_c('div',{on:{"click":_vm.switchTagModal}},[_c('button',{attrs:{"disabled":_vm.loading}},[_c('IconTag',{staticClass:"icon color-8 is-xsmall mr-1"}),_vm._v(" Tags ")],1)]),(!_vm.hideElementByCondition)?_c('div',{staticClass:"is-relative import_service_option__container",on:{"click":function () {
          _vm.onShowMoreButtonClick();
          _vm.openImportServiceModal();
        }}},[_c('button',{attrs:{"disabled":_vm.loading || _vm.historyView.isActive}},[_c('b-icon',{staticClass:"mr-1 color-8",attrs:{"size":"is-small","custom-size":"mdi-24px","icon":"swap-vertical-bold"}}),_vm._v(" Import new Service ")],1),_c('b-tag',{staticClass:"px-2",staticStyle:{"position":"absolute","top":"-0.5rem","right":"-1rem","height":"fit-content"},attrs:{"type":"is-info"}},[_c('p',[_vm._v("BETA")])])],1):_vm._e(),(_vm.canDisplayItineraryHistory)?_c('b-tooltip',{staticClass:"is-relative w-100 p-0 quote_history_option__container",attrs:{"label":("This feature is not available for Quotes created before " + (_vm.helper.format(
        '2024-09-23',
        _vm.datesFormat
      ))),"type":"is-warning","active":_vm.blockItineraryHistory}},[_c('div',{attrs:{"disabled":_vm.blockItineraryHistory},on:{"click":function () { return _vm.setItineraryHistoryModal(true); }}},[_c('button',{attrs:{"disabled":_vm.loading}},[_c('IconInfo',{staticClass:"icon color-8 is-xsmall"}),_vm._v(" Quote History ")],1),_c('b-tag',{staticClass:"px-2",staticStyle:{"position":"absolute","top":"-0.5rem","right":"-1rem","height":"fit-content"},attrs:{"type":"is-info"}},[_c('p',[_vm._v("BETA")])])],1)]):_vm._e(),(_vm.canDisplayItineraryPricesMatrix)?_c('div',{staticClass:"is-relative matrix_calculation__container",on:{"click":_vm.onViewItineraryPricesMatrixClick}},[_c('button',{staticClass:"is-flex is-align-items-center",attrs:{"disabled":_vm.loading}},[_c('b-icon',{staticClass:"mr-1",attrs:{"icon":"chart-line","custom-size":"mdi-18px"}}),_vm._v(" View Matrix Calculation ")],1),_c('b-tag',{staticClass:"px-2",staticStyle:{"position":"absolute","top":"-0.5rem","right":"-1rem","height":"fit-content"},attrs:{"type":"is-info"}},[_c('p',[_vm._v("BETA")])])],1):_vm._e()],1),_c('ImportNewServiceModal',{attrs:{"isModalOpen":_vm.isImportServiceModalOpen,"closeModal":_vm.closeImportServiceModal,"onContinue":_vm.importNewService}}),(_vm.isItineraryPricesMatrixModalOpen)?_c('ItineraryPricesMatrix',{attrs:{"loading":_vm.loading,"isFirstStepModalOpen":_vm.isItineraryPricesMatrixModalOpen,"closeFirstStepModal":function () { return _vm.setIsItineraryPricesMatrixModalOpen(false); }}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }